<script lang="ts" setup>
const general = [
  { title: 'Co potřebuji k vytvoření účtu?', text: 'K vytvoření účtu vám stačí emailová adresa a nastavit si heslo. Poté vám na uvedenou adresu přijde email s odkazem pro aktivaci vašeho účtu.' },
  { title: 'Jak postupovat v případě zapomenutého hesla?', text: 'Klikněte na “Přihlásit se” - “Zapomenuté heslo”. Zadejte vaši e-mailovou adresu, na kterou vám přijde odkaz, pomocí kterého nastavíte nové heslo.' },
  { title: 'Musím být registrovaný k provedení směny?', text: 'Ano, vytvoření účtu vám zabere necelou minutu. V pravém horním rohu klikněte na tlačítko: “Vytvořit účet”, zadejte vaši e-mailovou adresu a nastavte si heslo. Na vaši e-mailovou adresu vám bude zaslán odkaz pro aktivaci vašeho účtu.' },
  { title: 'Mohu provést směnu jako neverifikovaný uživatel?', text: 'Ano, směnu jako neverifikovaný uživatel lze provést v maximální výši 1.000 euro / 25.000 Kč. ' },
  { title: 'Jak probíhá verifikace a co všechno budu potřebovat?', text: 'Verifikace probíhá automatizovaně pomocí umělé inteligence. Zabere vám zhruba dvě minuty. Budete potřebovat dva doklady totožnosti. ' },
  { title: 'Jak zjistím, že byl můj účet verifikovaný?', text: 'Hned po ověření vašeho účtu Vám odešleme potvrzení na emailovou adresu. ' },
  { title: 'Musím být majitelem bankovního účtu?', text: 'Ano, váš bankovní účet musí být ověřen našimi pracovníky. Stačí nahrát dokument, na kterém bude číslo účtu a vaše jméno. Přidat účet můžete v sekci nastavení. ' },
  { title: 'Jak mohu přidat bankovní účet?', text: 'V levém sloupci kliknete na : “Můj účet” - “Bankovní účet” - “Přidat nový bankovní účet”. Následně zadáte číslo účtu, kód banky a nahrajete dokument (např: výpis z účtu), kde je vidět vaše jméno, příjmení a číslo účtu.' },
  { title: 'Kolik mohu nahrát bankovních účtů?', text: 'Počet bankovních účtů není nijak omezen. ' },
  { title: 'Jak dlouho trvá ověření bankovního účtu?', text: 'K schválení/zamítnutí bankovního účtu dochází téměř okamžitě. ' },
  { title: 'Musím být majitelem krypto peněženky?', text: 'Ne, kryptopeněženku neověřujeme. Důkladně však zkontrolujte její správnost. ' },
]
</script>

<template>
  <FAQAccordion :answers="general" table-name="default" />
</template>
