<template>
  <div :data-test="getCypressId('table-important')" flex flex-col gap-16px>
    <h2 heading5 font-600>
      Důležité
    </h2>
    <ol pl-16px list-outside list-decimal text-neutral-500 dark:text-neutral-500 paragraph-lg font-300>
      <li>
        Vždy si pečlivě zkontrolujte správnost vaší kryptoměnové peněženky. Jakmile je transakce jednou odeslána, již ji nelze vrátit zpátky.
      </li>
      <li>
        Pozor na podvodné telefonáty. Naši pracovníci vás nikdy nekontaktují s nabídkou investic do jiných projektů a kryptoměn.
      </li>
      <li>
        Pozor na podvodné projekty, které tvrdí, že s námi spolupracují. Veškeré naše partnery najdete na našich webových stránkách.
      </li>
      <li>
        Ujistěte se, že máte svou peněženku dostatečně zajištěnou a s nikým nesdílejte své přístupové údaje, heslo ani seedy.
      </li>
    </ol>
  </div>
</template>
