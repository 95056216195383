<script lang="ts" setup>
const orders = [
  { title: 'Jak vytvořím objednávku?', text: 'Nejprve se přihlaste do svého účtu. V kalkulačce zvolte měnu a částku, kterou chcete odeslat. Poté si vyberte měnu, kterou chcete obdržet. Její množství Vám systém automaticky dopočítá. Následně už jen stačí kliknout na tlačítko “Odeslat objednávku” a platbu dokončit podle instrukcí.' },
  { title: 'Mohu objednávku zrušit?', text: 'Ano, objednávku můžete kdykoliv zrušit. Přejděte do detailu objednávky a klikněte na tlačítko” Zrušit”.' },
  { title: 'Za jak dlouho obdržím finanční prostředky/kryptoměnu na svůj účet?', text: 'Finanční prostředky odesíláme v moment, kdy náš systém zaeviduje vaši platbu. Hned na to vám na email přijde zpráva s potvrzením úspěšné transakce. ' },
  { title: 'Proč je částka, kterou jsem obdržel jiná než v objednávce?', text: 'Částka v objednávce je aktuální v danou chvíli a je pouze orientační. Vzhledem k vysoké volatilitě kryptoměn může být finální částka odlišná. Ke směně dochází po připsání prostředků na náš účet/peněženku s aktuálním kurzem. ' },
  { title: 'Co potřebuju k provedení směny?', text: 'K provedení směny je potřeba mít založený účet a ověřený váš bankovní účet.' },
  { title: 'Co potřebuji k obdržení kryptoměny?', text: 'K obdržení kryptoměny potřebujete vlastnit kryptoměnou peněženku. ' },
  { title: 'Jaká je minimální částka k provedení směny?', text: 'Každá kryptoměna má svojí minimální částku, kterou naleznete v kalkulačce.' },
  { title: 'Jak mohu ovlivnit, aby finanční prostředky dorazily co nejrychleji?', text: 'Možností je hned několik. Nejlépe uděláte, když peníze odešlete ze stejné banky, jakou máme my. Obecně pomůže zaškrtnou možnost Okamžitá platba, případně peníze vložit přímo na náš účet. Připsání EUR na eurový účet trvá zhruba 24 hodin. ' },
]
</script>

<template>
  <FAQAccordion :answers="orders" table-name="table-orders" />
</template>
