<script setup lang="ts">
import { FAQGeneral, FAQImportant, FAQOrders } from '#components'

useHead({ title: 'Často kladené otázky (FAQ)' })

const tabs = [
  {
    title: 'Obecné - Nastavení profilu',
    component: FAQGeneral,
  },
  {
    title: 'Objednávky',
    component: FAQOrders,
  },
  {
    title: 'Důležité',
    component: FAQImportant,
  },
]
</script>

<template>
  <div fubex-container>
    <div :data-test="getCypressId('body-header')" flex flex-col gap-24px items-center text-center mt-72px>
      <h1 heading1 text-neutral-900 font-800>
        Často kladené otázky
      </h1>
      <h3 text-neutral-900 paragraph-md max-w-648px>
        Jelikož si vážíme věrných zákazníků, připravili jsme si pro vás odměny za vaší aktivitu.
      </h3>
    </div>
    <FTabs
      id="faq"
      :tabs="tabs"
      tab-list-center
      tab-list-class="mt-48px"
      tab-panels-class="mt-46px mb-62px"
    />
  </div>
  <WidgetContactForm />
</template>
